"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PickUp = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var PickUp = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsxs("svg", __assign({ width: "24", height: "24", viewBox: "1 -1 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [jsx_runtime_1.jsx("path", { d: "M12.334 5V8M16.7784 5V8M16.7784 8H12.334M16.7784 8V4.5M12.334 8V4.5", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }, void 0), jsx_runtime_1.jsx("path", { d: "M10.1111 15.1124L12.7778 16.0013C12.7778 16.0013 19.4444 14.668 20.3333 14.668C21.2222 14.668 21.2222 15.5569 20.3333 16.4457C19.4444 17.3346 16.3333 20.0013 13.6667 20.0013C11 20.0013 9.22222 18.668 7.44444 18.668H3", stroke: "currentColor", strokeWidth: "1.4", strokeLinecap: "round", strokeLinejoin: "round" }, void 0), jsx_runtime_1.jsx("path", { d: "M3 13.3333C3.88889 12.4444 5.66667 11.1111 7.44444 11.1111C9.22222 11.1111 13.4444 12.8889 14.1111 13.7778C14.7778 14.6667 13 15.5 13 15.5M8.33333 8.44444V4.88889C8.33333 4.65314 8.42698 4.42705 8.59368 4.26035C8.76038 4.09365 8.98647 4 9.22222 4H19.8889C20.1246 4 20.3507 4.09365 20.5174 4.26035C20.6841 4.42705 20.7778 4.65314 20.7778 4.88889V12", stroke: "currentColor", strokeWidth: "1.7", strokeLinecap: "round", strokeLinejoin: "round" }, void 0)] }), void 0) }), void 0));
};
exports.PickUp = PickUp;
